import { IOrder } from "../../interface/order";
import { format } from "date-fns";
import { Button } from "antd";
export default function OrderItem({ data }: { data: IOrder }) {
  return (
    <div className="order-item">
      <div className="order-item__header">
        <div>{format(new Date(data.createdAt), "dd-MM-yyyy hh:mm")}</div>
        <div>
          <strong>{data.status}</strong>
          {data?.paymentUrl && <Button type="primary" style={{ marginLeft: '10px' }} onClick={() => window.location.href = data.paymentUrl}>REPAY</Button>}
        </div>
      </div>
      <div className="order-item__list">
        {data?.orderItems?.map(({ product, size, quantity }, index) => (
          <div key={index} className="order-item__list-item">
            <div className="order-item__image">
              <img src={product.image} alt="" />
            </div>
            <div className="order-item__content">
              <div className="order-item__product-title">{product.name}</div>
              <div className="flex items-center justify-between">
                <div>Size: {size}</div>
                <div>x{quantity}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
