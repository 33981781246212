import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import MTInputNumber from "@app-components/input/MTInputNumber";
import MTSelect from "@app-components/select/MTSelect";
import { OptionData } from "@app-components/select/types";
import { SolidIcon } from "@app-components/svg/icon";
import { colorOptions, ringSizeOptions } from "@app-constants/product";
import { ICart } from "@app-interface/cart";
import { toMoney } from "@app-utils/number";

export default function NewCartItem({
  isReadonly,
  data,
  onUpdate,
  onDelete,
}: {
  isReadonly?: boolean;
  data: ICart;
  onUpdate?: (data: ICart) => void;
  onDelete?: () => void;
}) {
  const { t } = useTranslation();
  const { product, quantity, size, color } = data;

  const invalidRingSize = useMemo(() => {
    return !size ? t("message.chooseSize") : "";
  }, [size, t]);

  const handleUpdateData = (prop: any) => {
    onUpdate?.({
      ...data,
      ...prop,
    });
  };

  const handleChangeRingSize = (value: any) => {
    handleUpdateData({ size: value });
  };
  const handleChangeColor = (value: any) => {
    handleUpdateData({ color: value });
  };
  const handleChangeQuantity = (value: number | null) => {
    handleUpdateData({ quantity: value });
  };

  return (
    <div className="cart-item-new">
      <div className="cart">
        <div className="cart__image-group">
          <div className="cart__image">
            <img src={product.image} alt="" />
          </div>
        </div>
        <div className="content">
          <div className="cart__header">
            <Link
              className="cart__title truncate"
              to={`/product/${product.id}`}
            >
              {product.name}
            </Link>
          </div>
          <div className="cart__body">
            <div className="cart__input-group">
              <span className="label">{t("label.color")}</span>
              {isReadonly ? (
                color
              ) : (
                <MTSelect
                  className="cart__input mt-select--color"
                  options={colorOptions.map((color: OptionData) => ({
                    ...color,
                    label: t(color.label.toString()),
                  }))}
                  value={color}
                  onChange={handleChangeColor}
                  size="sm"
                />
              )}
            </div>
            <div className="flex flex-col relative">
              <div className="cart__input-group">
                <span className="label">{t("label.size")}</span>
                {isReadonly ? (
                  size
                ) : (
                  <MTSelect
                    className="cart__input mt-select--size"
                    label=""
                    options={ringSizeOptions}
                    value={size}
                    onChange={handleChangeRingSize}
                    size="sm"
                  />
                )}
              </div>
              <div className="error-message absolute">{invalidRingSize}</div>
            </div>

            <div className="cart__input-group">
              <span className="label">{t("label.quantity")}</span>
              {isReadonly ? (
                quantity
              ) : (
                <MTInputNumber
                  className="cart__input mt-select--quantity"
                  max={100}
                  min={1}
                  placeholder="Quantity"
                  value={quantity}
                  onChange={handleChangeQuantity}
                  size="sm"
                />
              )}
            </div>
            <div className="cart__price">
              <div className="cart__price__label">Price</div>
              <div className="cart__price__value">
                {toMoney(product.price * quantity)}đ
              </div>
            </div>
            <div className="cart__delete cart__action" onClick={onDelete}>
              <div className="cart_action-icon">
                <SolidIcon.Trash />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {!isReadonly && (
        <div className="cart__actions">
          <div className="cart__delete cart__action" onClick={onDelete}>
            <DeleteOutlined className="cart_action-icon" />
          </div>
        </div>
      )} */}
    </div>
  );
}
