import { EditFilled } from "@ant-design/icons";
import { Modal, Radio, message } from "antd";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddressInput from "@app-components/address/AddressInput";
import StyledButton from "@app-components/button/StyledButton";
import MTSelect from "@app-components/select/MTSelect";
import { OutlineIcon, SolidIcon } from "@app-components/svg/icon";
import { CreateAddressReq } from "@app-interface/address";
import { addressesListPayloadAtom, useUserWriteOnly } from "@app-jotai/user";
import addressServices from "@app-services/addressServices";
import useService from "@app-services/shared/use_service";

const useCreateAddress = () => useService(addressServices.createAddress);
const useEditAddress = () => useService(addressServices.editAddress);
const useDeleteAddress = () => useService(addressServices.deleteAddress);

const initAddressData = {
  primary: false,
  value: "",
};

export default function NewUserAddressForm({
  onSelectAddressId,
}: {
  onSelectAddressId: (id: number) => void;
}) {
  const { t } = useTranslation();
  const { getAddressesList } = useUserWriteOnly();

  const [messageApi, contextHolder] = message.useMessage();
  const [openModal, setOpenModal] = useState(false);
  const [openManageModal, setOpenManageModal] = useState(false);
  const [addressId, setAddressId] = useState<number>();
  const [editAddressId, setEditAddressId] = useState<number>();
  const [isEdit, setIsEdit] = useState(false);
  const [addressReq, setAddressReq] =
    useState<CreateAddressReq>(initAddressData);

  const { data: addressesList } = useAtomValue(addressesListPayloadAtom);
  const [createAddressPayload, onCreateAddress, clearCreateAddressState] =
    useCreateAddress();
  const [editAddressPayload, onEditAddress, clearEditAddressState] =
    useEditAddress();
  const [deleteAddressPayload, onDeleteAddress, clearDeleteAddressState] =
    useDeleteAddress();

  useEffect(() => {
    getAddressesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addressesList) {
      const id = addressesList?.models?.find((i) => i.primary)?.id;
      if (id) setAddressId(id);
    }
  }, [addressesList]);

  useEffect(() => {
    onSelectAddressId?.(addressId as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId]);

  useEffect(() => {
    if (deleteAddressPayload?.success) {
      setAddressId(undefined);
      messageApi.success(t("message.deleteAddressSucceed"));
      getAddressesList();
      clearDeleteAddressState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAddressPayload.success]);

  useEffect(() => {
    if (createAddressPayload?.success) {
      setAddressId(createAddressPayload?.data?.id || addressId);
      messageApi.success(t("message.createAddressSucceed"));
      getAddressesList();
      handleCloseModal();
      clearCreateAddressState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddressPayload.success]);

  useEffect(() => {
    if (editAddressPayload?.success) {
      getAddressesList();
      handleCloseModal();
      clearEditAddressState();
      messageApi.success(t("message.editAddressSucceed"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAddressPayload.success]);

  useEffect(() => {
    if (
      editAddressPayload.error ||
      createAddressPayload.error ||
      deleteAddressPayload.error
    ) {
      messageApi.success(
        t(
          editAddressPayload?.error ||
            createAddressPayload.error ||
            deleteAddressPayload.error
        )
      );
      setOpenModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editAddressPayload?.error,
    createAddressPayload.error,
    deleteAddressPayload.error,
  ]);

  const handleCreateAddress = () => {
    if (isEdit) {
      onEditAddress(editAddressId as number, addressReq);
      return;
    } else {
      onCreateAddress(addressReq);
    }
  };

  const handleCloseManageModal = () => {
    setOpenManageModal(false);
  };

  const handleCloseModal = () => {
    clearCreateAddressState();
    setOpenModal(false);
    setAddressReq(initAddressData);
  };

  const handleOnDeleteAddress = (id: number) => {
    onDeleteAddress(id);
  };

  const handleOnSelectAddressToEdit = (id: number) => {
    const addr = addressesList?.models?.find((item) => item.id === id);
    setAddressReq({
      value: addr?.value || "",
      primary: addr?.primary || false,
    });
    setEditAddressId(id);
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleOnAddNewAddress = () => {
    setAddressReq(initAddressData);
    setIsEdit(false);
    setOpenModal(true);
  };

  const handleOnSelectAddressItem = (id: number) => {
    setAddressId(id);
  };

  return (
    <>
      {contextHolder}
      <div className="mt-[16px]">
        <MTSelect
          className="w-full"
          placeholder={t("input.address.placeholder")}
          onChange={(e) => setAddressId(e)}
          options={addressesList?.models.map((item) => ({
            value: item.id,
            label: item.value,
          }))}
          value={addressId}
          size="sm"
          notFoundContent={
            <div className="flex justify-center items-center h-[64px]">
              <span
                className="underline cursor-pointer mt-title-lg"
                onClick={() => setOpenModal(true)}
              >
                {t("label.createNewAddress")}
              </span>
            </div>
          }
        />
        <div className="text-center pt-2">
          {!!addressesList?.models.length && (
            <span
              className="underline mt-title-lg cursor-pointer"
              onClick={() => {
                setOpenManageModal(true);
              }}
            >
              {t("label.manageAddresses")}
            </span>
          )}
        </div>
      </div>
      {openModal && (
        <Modal
          open={openModal}
          onOk={handleCreateAddress}
          onCancel={handleCloseModal}
          width={768}
          footer={
            <>
              <StyledButton
                onClick={handleCloseModal}
                className="outline mt-button-md"
              >
                {t("button.cancel")}
              </StyledButton>
              <StyledButton
                disabled={!addressReq.value}
                onClick={handleCreateAddress}
                className="primary mt-button-md"
              >
                {t("button.ok")}
              </StyledButton>
            </>
          }
        >
          <div className="mt-title-xl flex justify-center pb-[24px]">
            {isEdit ? t("label.editAddress") : t("label.createNewAddress")}
          </div>
          <div className="pt-4">
            <AddressInput
              initAddress={isEdit ? addressReq : undefined}
              onUpdateData={(data) => setAddressReq(data)}
            />
          </div>
        </Modal>
      )}
      {openManageModal && (
        <Modal
          open={openManageModal}
          onCancel={handleCloseManageModal}
          width={768}
          footer={
            <>
              <StyledButton
                onClick={handleCloseManageModal}
                className="outline mt-button-md"
              >
                {t("button.close")}
              </StyledButton>
            </>
          }
        >
          <div className="change-address-modal">
            <div className="mt-title-xl flex justify-center pb-[24px]">
              {t("label.manageAddresses")}
            </div>
            <div>
              <Radio.Group
                onChange={(e) => handleOnSelectAddressItem(e.target.value)}
                value={addressId}
                className="address-list"
              >
                {addressesList?.models?.map((item: any) => (
                  <Radio
                    value={item.id}
                    className={classNames(
                      "address-item",
                      item.id === addressId && "active"
                    )}
                    key={item.id}
                    rootClassName="test"
                  >
                    <div className="label-container">
                      <div>
                        <div className="label">{item.value}</div>
                        <div className="mt-text-body-sm text-metalic-silver">
                          {item.primary && t("label.defaultAddress")}
                        </div>
                      </div>

                      <div className="action-container">
                        <div
                          className="action-button"
                          onClick={() => handleOnDeleteAddress(item.id)}
                        >
                          <SolidIcon.Trash />
                        </div>
                        <div
                          onClick={() => handleOnSelectAddressToEdit(item.id)}
                          className="action-button"
                        >
                          <EditFilled />
                        </div>
                      </div>
                    </div>
                  </Radio>
                ))}
              </Radio.Group>
              <div className="add-new-address-container">
                <div
                  className="add-new-address-btn"
                  onClick={handleOnAddNewAddress}
                >
                  <OutlineIcon.PlusCircle />
                  <span className="">{t("label.createNewAddress")}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
