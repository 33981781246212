import {
  HistoryOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import ShoppingCartIcon from "@app-components/shopping/ShoppingCartIcon";
import { OutlineIcon } from "@app-components/svg/icon";
import { WEB_TYPE } from "@app-constants/common";
import { isLoggedAtom, useAuthWriteOnly } from "@app-jotai/auth";
import { profileAtom } from "@app-jotai/user";
import { PATHS } from "@app-utils/routes";

import HeaderLanguage from "./HeaderLanguage";

export default function UserInfo() {
  const isLogged = useAtomValue(isLoggedAtom);
  const profile = useAtomValue(profileAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { onSignout } = useAuthWriteOnly();

  const handleSignin = () => {
    if (!isLogged) {
      navigate(PATHS.login);
    }
  };
  const handleSignup = () => {
    navigate(PATHS.register);
  };

  const renderProfile = () => {
    return (
      <div className="flex items-center flex-row gap-2 md:gap-4">
        <HeaderLanguage />
        <ShoppingCartIcon />
        <Dropdown
          className="flex items-center justify-center"
          overlayClassName="mt-header__overlay-dropdown"
          menu={{
            items: [
              {
                label: (
                  <Link
                    to={PATHS.settings}
                    className="mt-header__dropdown-text"
                  >
                    <SettingOutlined />
                    <span className="label">{t("title.settings")}</span>
                  </Link>
                ),
                key: "settings",
              },
              {
                label: (
                  <Link to="/profile" className="mt-header__dropdown-text">
                    <UserOutlined />
                    <span className="label">{t("title.profile")}</span>
                  </Link>
                ),
                key: "profile",
              },
              {
                label: (
                  <Link to="/orders" className="mt-header__dropdown-text">
                    <HistoryOutlined />
                    <span className="label">{t("title.history")}</span>
                  </Link>
                ),
                key: "orders",
              },
              {
                label: (
                  <Link to={PATHS.gba} className="mt-header__dropdown-text">
                    <HistoryOutlined />
                    <span className="label">{t("title.gba")}</span>
                  </Link>
                ),
                key: "points",
              },
              {
                label: (
                  <span
                    onClick={onSignout}
                    className="mt-header__dropdown-text"
                  >
                    <OutlineIcon.Logout />
                    <span className="label">{t("button.signOut")}</span>
                  </span>
                ),
                key: "signou",
              },
            ],
          }}
          trigger={["click"]}
        >
          <div className="cursor-pointer">
            <div className="only-desktop mt-header__profile-dropdown">
              <span>{profile?.name || "Mate"}</span>
              <OutlineIcon.ChevronDown />
            </div>
            <span className="only-mobile">
              <OutlineIcon.User />
            </span>
          </div>
        </Dropdown>
      </div>
    );
  };

  return (
    <div className="user-info flex items-center">
      {WEB_TYPE ? (
        <HeaderLanguage />
      ) : isLogged ? (
        renderProfile()
      ) : (
        <div className=" flex flex-row gap-2 md:gap-4 justify-center items-center">
          <div
            className="only-desktop cursor-pointer mt-header__login-text"
            onClick={handleSignin}
          >
            {t("button.signin")}
          </div>
          <div
            onClick={handleSignup}
            className="only-desktop cursor-pointer mt-header__login-text"
          >
            {t("button.signup")}
          </div>

          <div onClick={handleSignin} className="only-mobile cursor-pointer">
            <OutlineIcon.User />
          </div>
          <HeaderLanguage />
          <ShoppingCartIcon />
        </div>
      )}
    </div>
  );
}
