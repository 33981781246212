import { InputNumber } from "antd";
import classNames from "classnames";
import isNil from "lodash/isNil";

import { OutlineIcon } from "@app-components/svg/icon";

import { MTInputNumberProps } from "./types";

export default function MTInputNumber({
  label,
  placeholder,
  value,
  onChange,
  min,
  max,
  className,
  disabled,
  readonly,
  size = "md",
}: MTInputNumberProps) {
  const handleOnClick = (value: number | null) => {
    onChange?.(value);
  };

  const handleClickAction = (type: string) => {
    const valTemp = value || 0;
    if (
      (type === "-" && !isNil(min) && valTemp <= min) ||
      (type === "+" && !isNil(max) && max <= valTemp) ||
      readonly ||
      disabled
    ) {
      return;
    }
    onChange?.(type === "+" ? valTemp + 1 : valTemp - 1);
  };

  return (
    <div
      className={classNames("mt-input-number", className, { [size]: !!size })}
    >
      {!!label && <div className="mt-input-number__label">{label}</div>}
      <InputNumber
        className="mt-input-number__container"
        disabled={disabled}
        readOnly={readonly}
        bordered={false}
        controls={false}
        value={value}
        min={min}
        max={max}
        addonBefore={
          <div
            className={classNames("action minus", {
              disabled,
              readonly,
              "not-allowed": min === value,
            })}
            onClick={() => handleClickAction("-")}
          >
            <OutlineIcon.MinusCircle />
          </div>
        }
        addonAfter={
          <div
            className={classNames("action plus", {
              disabled,
              readonly,
              "not-allowed": max === value,
            })}
            onClick={() => handleClickAction("+")}
          >
            <OutlineIcon.PlusCircle />
          </div>
        }
        placeholder={placeholder}
        onChange={handleOnClick}
      />
    </div>
  );
}
