import { Popover } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import StyledButton from "@app-components/button/StyledButton";
import MTInput from "@app-components/input/MTInput";
import orderServices from "@app-services/orderServices";
import useService from "@app-services/shared/use_service";

const useCheckCoupon = () => useService(orderServices.checkCoupon);

export default function CouponSection({
  onApply,
}: {
  onApply?: (code: string) => void;
}) {
  const { t } = useTranslation();
  const [checkCouponPayload, onCheckCoupon, clearCouponPayload] =
    useCheckCoupon();

  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [applied, setApplied] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const err =
      code && checkCouponPayload.data && !checkCouponPayload.data?.exists
        ? t("page.checkout.couponNotExist")
        : code && checkCouponPayload?.data?.used
        ? t("page.checkout.couponUsed")
        : "";

    if (!err && checkCouponPayload.success) {
      onApply?.(code || "");
      setApplied(code);
    } else {
      setApplied("");
      onApply?.("");
    }

    setError(err);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, checkCouponPayload.data]);

  useEffect(() => {
    if (checkCouponPayload.error) {
      onApply?.("");
      setApplied("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCouponPayload.error]);

  useEffect(() => {
    if (!code && checkCouponPayload.data) {
      clearCouponPayload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleOnSelectCoupon = (val: boolean) => {
    setShowModal(val);
  };

  const handleOnCheck = () => {
    onCheckCoupon(code);
  };

  return (
    <div className="flex flex-row justify-between items-center pt-[16px]">
      <div className="mt-title-lg">
        {applied && (
          <div className="flex gap-[16px] items-center">
            <div className="">{t("page.checkout.couponAppliedIs")}</div>
            <div className="coupon ">{t("page.checkout.discount")}</div>
          </div>
        )}
      </div>
      <Popover
        content={
          <div className="p-[16px]">
            <div className="flex flex-row gap-[16px] items-center">
              <div>
                <MTInput
                  label={t("page.checkout.enterCoupon")}
                  value={code}
                  onChangeText={setCode}
                  disabled={checkCouponPayload.loading}
                  error={error}
                />
              </div>
              <div>
                <StyledButton
                  disabled={!code}
                  className="primary"
                  onClick={handleOnCheck}
                  loading={checkCouponPayload.loading}
                >
                  {t("page.checkout.applyCoupon")}
                </StyledButton>
              </div>
            </div>
            <div className="flex flex-col justify-center flex-1 items-center mt-[16px] gap-[2px]">
              {t(checkCouponPayload.error)}
            </div>
          </div>
        }
        trigger="click"
        open={showModal}
        placement="bottom"
        onOpenChange={handleOnSelectCoupon}
        rootClassName="mx-[16px]"
      >
        <div className="mt-title-xl cursor-pointer py-[8px] px-[16px] underline hover:opacity-80">
          {t("page.checkout.selectCoupon")}
        </div>
      </Popover>
    </div>
  );
}
