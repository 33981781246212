import { useAtomValue, useSetAtom } from "jotai";
import qs from "query-string";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import StyledButton from "@app-components/button/StyledButton";
import CartItem from "@app-components/shopping/CartItem";
import CartSummary from "@app-components/shopping/CartSummary";
import { CartData } from "@app-interface/cart";
import { isLoggedAtom } from "@app-jotai/auth";
import { checkoutProductListAtom } from "@app-jotai/shopping";
import { formattedCartListAtom } from "@app-jotai/shopping/getters";
import { useProductWriteOnly } from "@app-jotai/shopping/setters";
import {
  GUEST_CHECKOUT,
  MEMBER_CHECKOUT,
  MEMBER_LOGIN_BEFORE_CHECKOUT,
  Mixpanel,
  SHOPPING_CART_PAGE,
} from "@app-mixpanel/index";
import { PATHS } from "@app-utils/routes";

export default function CartPage() {
  const { t } = useTranslation();
  const isLogged = useAtomValue(isLoggedAtom);
  const cartList = useAtomValue(formattedCartListAtom);
  const { onUpdateCartList, onDeleteCartItem } = useProductWriteOnly();
  const setCheckoutData = useSetAtom(checkoutProductListAtom);

  const navigate = useNavigate();

  const selectedCartList = useMemo(
    () => cartList.filter((i) => i.selected),
    [cartList]
  );

  useEffect(() => {
    Mixpanel.track(SHOPPING_CART_PAGE);
  }, []);

  const handleUpdateCartItem = (currentId: string, data: CartData) => {
    onUpdateCartList(data, currentId);
  };

  const handleDeleteCartItem = (currentId: string) => {
    onDeleteCartItem(currentId);
  };

  const handleCheckout = (isGuest = false) => {
    Mixpanel.track(
      isLogged
        ? MEMBER_CHECKOUT
        : isGuest
        ? GUEST_CHECKOUT
        : MEMBER_LOGIN_BEFORE_CHECKOUT
    );

    navigate(
      isGuest || isLogged
        ? PATHS.checkout
        : "/login?" + qs.stringify({ redirect: PATHS.checkout })
    );
    setCheckoutData(selectedCartList);
  };

  return (
    <div className="cart-page container flex">
      <div className="cart-list">
        <div className="title">{t("title.cart")}</div>
        {cartList?.map((data, index) => (
          <CartItem
            key={index}
            data={data}
            onUpdate={(newData) =>
              handleUpdateCartItem(data?.cartId || "", newData)
            }
            onDelete={() => handleDeleteCartItem(data.cartId || "")}
          />
        ))}
        <div className="pt-10 text-right">
          <Link to={PATHS.orders}>
            <StyledButton className="primary">
              {t("button.viewOrders")}
            </StyledButton>
          </Link>
        </div>
      </div>
      <CartSummary
        selectedCartList={selectedCartList}
        footer={
          <div className="text-left pt-4">
            {!isLogged && (
              <div className="pb-2">
                <StyledButton
                  className="primary w-[190px]"
                  disabled={!selectedCartList?.length}
                  onClick={() => handleCheckout(true)}
                >
                  {t("button.guestCheckout")}
                </StyledButton>
              </div>
            )}
            <div>
              <StyledButton
                className="primary w-[190px]"
                disabled={!selectedCartList?.length}
                onClick={() => handleCheckout()}
              >
                {isLogged ? t("button.checkout") : t("button.memberCheckout")}
              </StyledButton>
            </div>
          </div>
        }
      />
    </div>
  );
}
