import { DeleteOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import MTInputNumber from "@app-components/input/MTInputNumber";
import MTSelect from "@app-components/select/MTSelect";
import { OptionData } from "@app-components/select/types";
import { colorOptions, ringSizeOptions } from "@app-constants/product";
import { ICart } from "@app-interface/cart";
import { toMoney } from "@app-utils/number";

export default function CartItem({
  isReadonly,
  data,
  onUpdate,
  onDelete,
}: {
  isReadonly?: boolean;
  data: ICart;
  onUpdate?: (data: ICart) => void;
  onDelete?: () => void;
}) {
  const { t } = useTranslation();
  const { product, quantity, size, selected, color } = data;

  const handleUpdateData = (prop: any) => {
    onUpdate?.({
      ...data,
      ...prop,
    });
  };

  const handleChangeRingSize = (value: any) => {
    handleUpdateData({ size: value });
  };
  const handleChangeColor = (value: any) => {
    handleUpdateData({ color: value });
  };
  const handleChangeQuantity = (value: number | null) => {
    handleUpdateData({ quantity: value });
  };

  return (
    <div className="cart-item">
      <div className="cart">
        <div className="cart__image-group">
          {!isReadonly && (
            <div className="cart__selection">
              <Checkbox
                checked={selected}
                onChange={(e) =>
                  onUpdate?.({
                    ...data,
                    selected: e.target.checked,
                  })
                }
              />
            </div>
          )}
          <div className="cart__image">
            <img src={product.image} alt="" />
          </div>
        </div>
        <div className="content">
          <div className="cart__header">
            <Link
              className="cart__title truncate"
              to={`/product/${product.id}`}
            >
              {product.name}
            </Link>

            <div className="cart__price total">
              {toMoney(product.price * quantity)} VNĐ
            </div>
          </div>
          <div className="cart__input-group">
            <span className="label">{t("label.color")}:</span>
            {isReadonly ? (
              color
            ) : (
              <MTSelect
                className="cart__input"
                options={colorOptions.map((color: OptionData) => ({
                  ...color,
                  label: t(color.label.toString()),
                }))}
                value={color}
                onChange={handleChangeColor}
                size="sm"
              />
            )}
          </div>
          <div className="cart__input-group">
            <span className="label">{t("label.size")}:</span>
            {isReadonly ? (
              size
            ) : (
              <MTSelect
                className="cart__input"
                label=""
                options={ringSizeOptions}
                value={size}
                onChange={handleChangeRingSize}
                size="sm"
              />
            )}
          </div>
          <div className="cart__input-group">
            <span className="label">{t("label.quantity")}:</span>
            {isReadonly ? (
              quantity
            ) : (
              <MTInputNumber
                className="cart__input"
                max={100}
                min={1}
                placeholder="Quantity"
                value={quantity}
                onChange={handleChangeQuantity}
                size="sm"
              />
            )}
          </div>
        </div>
      </div>
      {!isReadonly && (
        <div className="cart__actions">
          <div className="cart__delete cart__action" onClick={onDelete}>
            <DeleteOutlined className="cart_action-icon" />
          </div>
        </div>
      )}
    </div>
  );
}
