import { Divider } from "antd";
import { useTranslation } from "react-i18next";

import { ICart } from "../../interface/cart";
import { toMoney } from "../../utils/number";

const shippingFee = 0;

export default function CartSummary({
  selectedCartList,
  footer,
}: {
  selectedCartList: ICart[];
  footer?: any;
}) {
  const { t } = useTranslation();
  const subTotal = selectedCartList?.reduce((result, item) => {
    result += item.quantity * item.product.price;
    return result;
  }, 0);

  return (
    <div className="cart-summary">
      <div className="title">{t("label.summary")}</div>
      <div className="line">
        <div className="label">{t("label.subTotal")}</div>
        <div className="value">
          {subTotal ? toMoney(subTotal) + " VNĐ" : "--"}
        </div>
      </div>
      <div className="line">
        <div className="label">{t("label.estDelivery")}</div>
        <div className="value">{toMoney(shippingFee) || t("label.free")}</div>
      </div>
      <Divider />
      <div className="line">
        <div className="label">{t("label.total")}</div>
        <div className="value font-bold">
          {subTotal ? toMoney(subTotal + shippingFee) + " VNĐ" : "--"}
        </div>
      </div>
      <Divider />
      {footer}
    </div>
  );
}
