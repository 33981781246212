import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { OutlineIcon } from "@app-components/svg/icon";

import { cartListAtom } from "../../jotai/shopping";

export default function ShoppingCartIcon() {
  const cartList = useAtomValue(cartListAtom);

  const total = useMemo(() => cartList?.length || 0, [cartList]);

  return (
    <Link to="/checkout">
      <div className="pointer shopping-cart">
        <OutlineIcon.ShoppingCart />
        {!!total && <div className="shopping-cart__total">{total}</div>}
      </div>
    </Link>
  );
}
