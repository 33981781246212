import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import OrderItem from "../../components/shopping/OrderItem";
import { LocalStorageKeys } from "../../constants/common";
import { IOrder } from "../../interface/order";
import { isLoggedAtom } from "../../jotai/auth";
import orderServices from "../../services/orderServices";
import useService from "../../services/shared/use_service";
import OrderSearching from "./OrderSearching";

const useOrdersList = () => useService(orderServices.getOrdersList);

export default function OrdersHistory() {
  const isLogged = useAtomValue(isLoggedAtom);
  return isLogged ? <UserOrdersHistory /> : <AnonymousOrdersHistory />;
}

function AnonymousOrdersHistory() {
  const [ordersList, setOrdersList] = useState<IOrder[]>([]);

  useEffect(() => {
    handleGetOrders();
  }, []);

  const handleGetOrders = () => {
    let orders = [];
    try {
      orders =
        JSON.parse(
          window.localStorage.getItem(LocalStorageKeys.ANONYMOUS_ORDERS) || "[]"
        ) || [];
    } catch (error) {
      orders = [];
    }
    setOrdersList(orders as IOrder[]);
  };

  return <OrderSearching initOrdersList={ordersList} />;
}

function UserOrdersHistory() {
  const [payload, getOrdersList] = useOrdersList();

  const { models: ordersList }: { models: IOrder[]; metadata: any } =
    payload?.data || {};

  useEffect(() => {
    getOrdersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full">
      <div className="container w-full">
        {ordersList?.map((i) => (
          <OrderItem data={i} key={i.id} />
        ))}
      </div>
    </div>
  );
}
