import { SearchOutlined } from "@ant-design/icons";
import { Input, Tabs } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Province } from "@app-interface/common";
import { removeAccents } from "@app-utils/string";

const filterItems = (str: string, arr: Province[]) =>
  !str?.trim()
    ? arr
    : arr.filter((i) =>
        removeAccents(i.name_with_type)
          .toLowerCase()
          .includes(removeAccents(str.trim()).toLowerCase())
      );

export default function AddressPopover({
  ward,
  district,
  province,
  listProvinces,
  listDistricts,
  listWards,
  onSelectProvice,
  onSelectDistrict,
  onSelectWard,
  onClose,
}: {
  ward: string;
  district: string;
  province: string;
  listProvinces: Province[];
  listDistricts: Province[];
  listWards: Province[];
  onSelectProvice: (value: string) => void;
  onSelectDistrict: (value: string) => void;
  onSelectWard: (value: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("1");
  const [searchText, setSearchText] = useState("");

  const placeholderText = useMemo(() => {
    let text = "";
    switch (selectedTab) {
      case "1":
        text = t("content.searchCityProvince");
        break;

      case "2":
        text = t("content.searchDistrict");
        break;

      case "3":
        text = t("content.searchWard");
        break;

      default:
        break;
    }
    return text;
  }, [selectedTab, t]);

  useEffect(() => {
    setSearchText("");
  }, [selectedTab]);

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: t("label.city"),
          },
          {
            key: "2",
            label: t("label.district"),
            disabled: !province,
          },
          {
            key: "3",
            label: t("label.ward"),
            disabled: !district,
          },
        ]}
        activeKey={selectedTab}
        onChange={(e) => setSelectedTab(e)}
      />
      <div className="">
        <div>
          <div className="px-[16px] py-[8px]">
            <Input
              placeholder={placeholderText}
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="overflow-auto h-[300px]">
            {selectedTab === "1" &&
              filterItems(searchText, listProvinces).map((i, index) => (
                <div
                  key={index}
                  className={classNames("address-item", {
                    active: i.code === province,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectProvice(i.code);
                    setSelectedTab("2");
                  }}
                >
                  {i[i.type === "thanh-pho" ? "name_with_type" : "name"]}
                </div>
              ))}
            {selectedTab === "2" &&
              filterItems(searchText, listDistricts).map((i, index) => (
                <div
                  key={index}
                  className={classNames("address-item", {
                    active: i.code === district,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectDistrict(i.code);
                    setSelectedTab("3");
                  }}
                >
                  {i.name_with_type}
                </div>
              ))}
            {selectedTab === "3" &&
              filterItems(searchText, listWards).map((i, index) => (
                <div
                  key={index}
                  className={classNames("address-item", {
                    active: i.code === ward,
                  })}
                  onClick={() => {
                    onSelectWard(i.code);
                    setSelectedTab("1");
                    onClose();
                  }}
                >
                  {i.name_with_type}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
