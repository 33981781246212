import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { LocalStorageKeys } from "@app-constants/common";
import { CartData } from "@app-interface/cart";
import { IProduct } from "@app-interface/product";

import { INIT_PAYLOAD } from "../constants";
import { Payload } from "../types";

const listProductsPayloadAtom = atom<Payload<any>>(INIT_PAYLOAD);

const productDetailPayloadAtom = atom<Payload<IProduct>>(INIT_PAYLOAD);

interface ICartRefData {
  refId: string;
  items: CartData[];
}

const cartListAtom = atomWithStorage<CartData[]>(LocalStorageKeys.CART, []);

const cartListRefAtom = atomWithStorage<ICartRefData>(
  LocalStorageKeys.CART_REF,
  {
    refId: "",
    items: [] as CartData[],
  }
);

const checkoutProductListAtom = atomWithStorage<CartData[]>(
  LocalStorageKeys.CHECKOUT,
  []
);

export {
  listProductsPayloadAtom,
  productDetailPayloadAtom,
  cartListAtom,
  checkoutProductListAtom,
  cartListRefAtom,
};
