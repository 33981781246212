import { Input } from "antd";
import classNames from "classnames";

import { MTInputOutlineProps } from "./types";

export default function MTInputOuline({
  label,
  placeholder,
  value,
  onChange,
  className,
  disabled,
  readonly,
  size,
}: MTInputOutlineProps) {
  const handleOnClick = (value: any) => {
    onChange?.(value.target.value);
  };

  return (
    <div className={classNames("mt-input-outline", className)}>
      {!!label && <div className="mt-input-outline__label">{label}</div>}
      <Input
        className="mt-input-outline__container"
        disabled={disabled}
        readOnly={readonly}
        value={value}
        placeholder={placeholder}
        onChange={handleOnClick}
      />
    </div>
  );
}
