import { Drawer } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import MTRing from "@app-assets/images/mt-ring.png";
import { WEB_TYPE, WEB_TYPE_NAME } from "@app-constants/common";
import { getRoutes } from "@app-utils/routes";

interface INavigationLinkData {
  title: string;
  path: string;
  pattern?: string;
  isDirect?: boolean;
}

export default function HeaderNavigation({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const routes = getRoutes(t);

  const navigationLinks: INavigationLinkData[] =
    WEB_TYPE === WEB_TYPE_NAME.PNS
      ? [routes.partnershipSupport]
      : [
          routes.aboutUs,
          { ...routes.product, path: `${routes.product.path}/1` },
          routes.contactsUs,
        ];

  const handleNavigate = (link: string, isDirect?: boolean) => {
    onClose();
    navigate(link);
  };

  const styles = useCallback(
    (link: any) => {
      return matchPath(link?.pattern ? link.pattern : link, location.pathname);
    },
    [location.pathname]
  );

  return (
    <Drawer
      contentWrapperStyle={{ paddingTop: 82, height: "auto" }}
      placement="top"
      closable={false}
      onClose={onClose}
      open={open}
      key={"top"}
      zIndex={999}
      className="mt-header__drawer"
    >
      <div className="container mt-header__drawer-body">
        <div className="mt-header__drawer-intro">
          <div className="mt-header__drawer-right">
            <div className="mt-header__drawer-title">The Ring</div>
            <div className="mt-header__drawer-img">
              <img src={MTRing} alt="" />
            </div>
          </div>
          <div className="mt-text-body-xl mt-header__drawer-desc">
            {t("content.menuDesc")}
          </div>
        </div>
        <div className="mt-header__drawer-divider">
          <span></span>
        </div>
        <div className="mt-text-body-xxl flex-1 link">
          {navigationLinks.map((link) => (
            <div
              key={link.title}
              className={!!styles(link) ? "active" : ""}
              onClick={() => handleNavigate(link.path, link?.isDirect)}
            >
              {link.title}
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
}
