import { cloneDeep } from "lodash";

import { LocalStorageKeys } from "@app-constants/common";
import { IAddress } from "@app-interface/address";
import { IOrder } from "@app-interface/order";
import { IProduct } from "@app-interface/product";

export const getAnonymousAddresses = () => {
  try {
    const addresses =
      JSON.parse(
        window.localStorage.getItem(LocalStorageKeys.ANONYMOUS_ADDRESSES) ||
          "[]"
      ) || [];
    return addresses;
  } catch (error) {
    return [];
  }
};

export const storeNewAnonymousAddress = (address: IAddress) => {
  try {
    const addresses = getAnonymousAddresses();
    const newAddresses = cloneDeep(addresses);
    newAddresses.push(address);
    window.localStorage.setItem(
      LocalStorageKeys.ANONYMOUS_ADDRESSES,
      JSON.stringify(newAddresses)
    );
    return newAddresses;
  } catch (error) {}
};

export const storeNewAnonymousOrder = (order: IOrder, tempPayload: any) => {
  let anonymousOrders = [];
  try {
    anonymousOrders =
      JSON.parse(
        window.localStorage.getItem(LocalStorageKeys.ANONYMOUS_ORDERS) || "[]"
      ) || [];
  } catch (error) {
    anonymousOrders = [];
  }
  anonymousOrders.push({
    ...order,
    orderItems: tempPayload.checkoutData.map(
      ({
        product,
        quantity,
        size,
      }: {
        product: IProduct;
        quantity: number;
        size: number;
      }) => ({
        product,
        quantity,
        size,
      })
    ),
  });
  window.localStorage.setItem(
    LocalStorageKeys.ANONYMOUS_ORDERS,
    JSON.stringify(anonymousOrders)
  );
};
