import RingPng from "@app-assets/images/mt-ring.png";
import PouchPng from "@app-assets/images/pouch.png";
import ManualPng from "@app-assets/images/user-manual.png";

export const BOX_MATERIAL = [
  {
    image: RingPng,
    placeholder: "page.product.theRing",
  },
  {
    image: ManualPng,
    placeholder: "page.product.userManual",
  },
  {
    image: PouchPng,
    placeholder: "page.product.pouch",
  },
];
