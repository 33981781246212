import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

import StyledButton from "@app-components/button/StyledButton";

import ThumbnailM from "../../assets/images/des-to-fit-m-thumb.png";
import Thumbnail from "../../assets/images/des-to-fit-thumb.png";
import FunctionIntroItem from "./FunctionIntroItem";

export default function Lifestyle() {
  const { t } = useTranslation();
  const INTRO_DATA = {
    title: t("page.landing.part.lifeStyle.intro.title"),
    desc: (
      <>
        <p>{t("page.landing.part.lifeStyle.intro.content")}</p>
        <Link to="/product/1">
          <StyledButton className="primary">
            {t("button.preOrder")}
          </StyledButton>
        </Link>
      </>
    ),
  };
  return (
    <div className="">
      <div className="lp-function-intro-section lp-lifestyle">
        <div className="container">
          <MediaQuery minWidth={992}>
            <FunctionIntroItem
              {...INTRO_DATA}
              id="des-to-fit"
              videoUrl="https://video.wixstatic.com/video/9a71f9_edc58ede2114443b92e94bae79c55edc/1080p/mp4/file.mp4"
              thumbnail={Thumbnail}
            />
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <FunctionIntroItem
              {...INTRO_DATA}
              id="des-to-fit-m"
              videoUrl="https://video.wixstatic.com/video/9a71f9_e42a034e529c4a039014b5decf23b435/1080p/mp4/file.mp4"
              thumbnail={ThumbnailM}
            />
          </MediaQuery>
        </div>
      </div>
    </div>
  );
}
