import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import StyledButton from "@app-components/button/StyledButton";
import MTBreadcrumb from "@app-components/header/MTBreadcrumb";
import useScrollToTop from "@app-hook/useScrollToTop";
import { IPointHistory } from "@app-interface/item";
import itemServices from "@app-services/itemServices";
import useService from "@app-services/shared/use_service";
import { formatDatetime } from "@app-utils/datetime";
import { getRoutes } from "@app-utils/routes";

const useFetchItems = () => useService(itemServices.getItems);
const useFetchPointHistory = () => useService(itemServices.getPointHistory);

export default function GBALandingPage() {
  const { t } = useTranslation();
  const routes = getRoutes(t);

  const [itemsPayload, onFetchItems] = useFetchItems();
  const [pointHistoryPayload, onFetchPointHistory] = useFetchPointHistory();

  useScrollToTop();
  useEffect(() => {
    onFetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemsPayload.success && itemsPayload.data?.models?.[0]) {
      onFetchPointHistory(itemsPayload.data?.models?.[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPayload.success]);

  return (
    <div className="gba-page container">
      <MTBreadcrumb items={[routes.home, routes.profile, routes.gba]} />
      {itemsPayload.data?.models?.[0] && pointHistoryPayload.data ? (
        <div className="flex flex-col justify-center items-center">
          <div className="card bg-silver-100 flex flex-row items-center justify-between">
            <div className="mt-text-body-xl">{t("page.gba.balance")}</div>
            <div className="flex items-center gap-[8px]">
              <span className="mt-title-xxl">
                {pointHistoryPayload?.data?.point?.amount}
              </span>
              <span className="mt-title-lg"> {t("page.gba.points")}</span>
            </div>
          </div>
          <div className="card bg-silver-100 flex flex-row overflow-x-auto">
            <table className="hidden sm:table mt-table border-collapse  w-full text-left">
              <thead>
                <tr>
                  <th>
                    <div className="cell">{t("page.gba.ringLabel")}</div>
                  </th>
                  <th>
                    <div className="cell">{t("page.gba.date")}</div>
                  </th>
                  <th>
                    <div className="cell">{t("page.gba.note")}</div>
                  </th>
                  <th>
                    <div className="cell">{t("page.gba.amount")}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pointHistoryPayload.data?.histories?.map(
                  (history: IPointHistory, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className="cell">
                          {itemsPayload.data?.models?.[0].label}
                        </div>
                      </td>
                      <td>
                        <div className="cell">
                          {formatDatetime(history?.updatedAt || "")}
                        </div>
                      </td>
                      <td>
                        <div className="cell whitespace-pre-line">
                          {history.description}
                        </div>
                      </td>
                      <td>
                        <div className="cell">
                          <div className={history.amount < 0 ? "minus" : "add"}>
                            {history.amount}
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <div className="flex flex-col gap-8 sm:hidden w-full">
              {pointHistoryPayload.data?.histories?.map(
                (history: IPointHistory, index: number) => (
                  <div className="flex flex-col gap-4 info-card" key={index}>
                    <div className="row-info">
                      <div className="title">{t("page.gba.ringLabel")}:</div>
                      <div>{itemsPayload.data?.models?.[0].label}</div>
                    </div>
                    <div className="row-info">
                      <div className="title">{t("page.gba.date")}:</div>
                      <div>{formatDatetime(history?.updatedAt || "")}</div>
                    </div>
                    <div className="row-info">
                      <div className="title">{t("page.gba.note")}:</div>
                      <div className="whitespace-pre-line">
                        {history.description}
                      </div>
                    </div>
                    <div className="row-info">
                      <div className="title">{t("page.gba.amount")}:</div>
                      <div className={history.amount < 0 ? "minus" : "add"}>
                        {history.amount}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center  mb-[30px]">
          <div className="card bg-silver-100 flex items-center flex-col gap-[40px]">
            <div className="pt-[56px]">
              <span className="mt-display-md">{t("page.gba.emptyRing")}</span>
            </div>
            <div className="w-[150px] pb-[56px]">
              <Link to="/product/1">
                <StyledButton className="outline mt-button-lg w-full">
                  {t("page.landing.part.bannerVideo.preOrder")}
                </StyledButton>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
