import { atom, useSetAtom } from "jotai";
import cloneDeep from "lodash/cloneDeep";

import { CartData } from "@app-interface/cart";
import { migrateCartData } from "@app-jotai/utils";
import { generateCartId } from "@app-utils/cart";
import { toNumber } from "@app-utils/number";

import { getProductApiAtom, getProductsApiAtom } from "./api_atoms";
import { cartListAtom, cartListRefAtom } from "./atoms";

const clearPurchasedCartAtom = atom(null, (get, set) => {
  const cartList = get(cartListAtom);
  set(
    cartListAtom,
    cartList.filter((i) => !i.selected)
  );
});

const handleUpdateCartList = (
  cartList: CartData[],
  item: CartData,
  currentId?: string
): CartData[] => {
  let currentIndex = -1;
  const newId = generateCartId(item.product.id, item.size, item.color);
  const newCartList: CartData[] = migrateCartData(cloneDeep(cartList));
  const sameItem = newCartList.find((itm, idx) => {
    currentIndex = idx;
    return itm.cartId === newId;
  });
  if (((currentId && currentId !== newId) || !currentId) && sameItem) {
    const updated = {
      ...item,
      quantity: toNumber(item.quantity) + toNumber(sameItem.quantity),
    };
    if (currentId) {
      const removeIndex = newCartList.findIndex(
        (itm) => itm.cartId === currentId
      );
      newCartList[currentIndex] = updated;
      newCartList.splice(removeIndex, 1);
    } else {
      newCartList[currentIndex] = updated;
    }
  } else if (!!currentId) {
    currentIndex = newCartList.findIndex((item) => item.cartId === currentId);
    newCartList[currentIndex] = { ...item, cartId: newId };
  } else {
    newCartList.push(item);
  }
  return newCartList;
};

const updateCartListAtom = atom(
  null,
  (get, set, item: CartData, currentId?: string) => {
    const cartList = get(cartListAtom);
    const newCartList = handleUpdateCartList(cartList, item, currentId);
    set(cartListAtom, newCartList);
  }
);
const deleteCartItem = atom(null, (get, set, currentId?: string) => {
  const cartList = get(cartListAtom);
  const newCartList = cloneDeep(cartList);
  const idIndex = newCartList.findIndex((item) => item.cartId === currentId);

  if (idIndex !== -1) {
    newCartList.splice(idIndex, 1);
    set(cartListAtom, newCartList);
  }
});

const updateCartRefListAtom = atom(
  null,
  (get, set, item: CartData, currentId?: string, refId?: string) => {
    const data = get(cartListRefAtom);
    const cartList = data.items;
    if (!!refId) {
      if (refId === data.refId) {
        const newCartList = handleUpdateCartList(cartList, item, currentId);
        set(cartListRefAtom, {
          refId: refId || data.refId,
          items: newCartList,
        });
      } else {
        set(cartListRefAtom, { refId, items: [item] });
      }
    } else {
      set(cartListRefAtom, { items: [], refId: "" });
    }
  }
);

const deleteCartRefItem = atom(null, (get, set, currentId?: string) => {
  const data = get(cartListRefAtom);
  const cartList = data.items;

  const newCartList = cloneDeep(cartList);
  const idIndex = newCartList.findIndex((item) => item.cartId === currentId);

  if (idIndex !== -1) {
    newCartList.splice(idIndex, 1);
    set(cartListRefAtom, { ...data, items: newCartList });
  }
});

const clearCartRefData = atom(null, (_, set) => {
  set(cartListRefAtom, { items: [], refId: "" });
});

const useProductWriteOnly = () => {
  const onGetProducts = useSetAtom(getProductsApiAtom);

  const onGetProduct = useSetAtom(getProductApiAtom);

  const onClearPurchasedCart = useSetAtom(clearPurchasedCartAtom);

  const onUpdateCartList = useSetAtom(updateCartListAtom);

  const onDeleteCartItem = useSetAtom(deleteCartItem);

  const onUpdateCartRefList = useSetAtom(updateCartRefListAtom);

  const onDeleteCartRefItem = useSetAtom(deleteCartRefItem);

  const onClearCartRefData = useSetAtom(clearCartRefData);

  return {
    onGetProduct,
    onGetProducts,
    onClearPurchasedCart,
    onUpdateCartList,
    onDeleteCartItem,
    onUpdateCartRefList,
    onDeleteCartRefItem,
    onClearCartRefData,
  };
};

export { useProductWriteOnly };
