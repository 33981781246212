import { Select } from "antd";
import classNames from "classnames";

import { StyledSelectProps } from "./types";

export default function MTSelect({
  label,
  placeholder,
  options,
  value,
  disabled,
  onChange,
  className,
  size = "md",
  loading,
  showSearch,
  filterSort,
  filterOption,
  optionFilterProp,
  notFoundContent,
}: StyledSelectProps) {
  const handleOnClick = (value: string | number) => {
    if (!disabled) {
      onChange?.(value);
    }
  };

  return (
    <div className={classNames("mt-select", className, { [size]: !!size })}>
      {!!label && <div className="mt-select__label">{label}</div>}
      <Select
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleOnClick}
        value={value}
        loading={loading}
        showSearch={showSearch}
        filterOption={filterOption}
        filterSort={filterSort}
        optionFilterProp={optionFilterProp}
        notFoundContent={notFoundContent}
      >
        {options?.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
