import { AutoComplete } from "antd";
import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorAnimation from "@app-components/lotties/ErrorAnimation";
import LoadingAnimation from "@app-components/lotties/LoadingAnimation";
import OrderItem from "@app-components/shopping/OrderItem";
import { IOrder } from "@app-interface/order";
import { isLoggedAtom } from "@app-jotai/auth";
import orderServices from "@app-services/orderServices";
import useService from "@app-services/shared/use_service";

const useOrderDetail = () => useService(orderServices.getOrderDetail);

export default function OrderSearching({
  initOrdersList,
}: {
  initOrdersList?: IOrder[];
}) {
  const { t } = useTranslation();
  const [ordersList, setOrdersList] = useState<IOrder[]>([]);
  const isLogged = useAtomValue(isLoggedAtom);
  const [refNo, setRefNo] = useState("");
  const [{ data: orderDetail, success, loading, error }, getOrderDetail] =
    useOrderDetail();

  useEffect(() => {
    if (initOrdersList) {
      setOrdersList(initOrdersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initOrdersList]);

  const onSelect = (data: string) => {
    getOrderDetail(data, !isLogged);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Enter") return;
    getOrderDetail(refNo, !isLogged);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center">
          <LoadingAnimation />
        </div>
      );
    } else if (success) {
      return (
        <div>
          <OrderItem data={orderDetail} />
        </div>
      );
    } else if (error) {
      return (
        <div className="flex flex-col items-center">
          <ErrorAnimation />

          <div className="text-danger">{t(error)}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full">
      <div className="container w-full pt-10">
        <span className="font-semibold">{t("input.refNo.label")}: </span>
        <AutoComplete
          options={ordersList.map((i) => ({ value: i.referenceNo + "" }))}
          style={{ width: 360 }}
          onSelect={onSelect}
          onSearch={(text) => setRefNo(text)}
          placeholder={t("input.refNo.placeholder")}
          onKeyUp={handleKeyUp}
        />

        <div className="pt-10">{renderContent()}</div>
      </div>
    </div>
  );
}
