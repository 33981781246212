import { OptionData } from "@app-components/select/types";

export const colorOptions: OptionData[] = [
  { value: "black", label: "page.product.black" },
];
export const ringSizeOptions: OptionData[] = new Array(11)
  .fill(5)
  .map((item, index) => ({
    label: item + index,
    value: item + index,
  }));

export const circumferenceOptions: OptionData[] = [
  4.93, 5.18, 5.43, 5.68, 5.93, 6.22, 6.47, 6.72, 6.97, 7.22, 7.47,
].map((value: number, index: number) => ({
  label: value + " cm",
  value: index + 5,
}));
