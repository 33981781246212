import { atom } from "jotai";

import { CartData } from "@app-interface/cart";
import { IProduct } from "@app-interface/product";
import { migrateCartData } from "@app-jotai/utils";

import { cartListAtom, listProductsPayloadAtom } from "./atoms";

const listProductsAtom = atom<IProduct[]>(
  (get) => get(listProductsPayloadAtom)?.data || []
);
const formattedCartListAtom = atom<CartData[]>((get) => {
  return migrateCartData(get(cartListAtom));
});

export { listProductsAtom, formattedCartListAtom };
