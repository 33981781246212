import { Modal } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddressInput from "@app-components/address/AddressInput";
import StyledButton from "@app-components/button/StyledButton";
import { CreateAddressReq, IAddress } from "@app-interface/address";
import { getAnonymousAddresses } from "@app-utils/anonymousOrder";

export default function NewAnonymousAddressForm({
  onChangeAddress,
}: {
  onChangeAddress: (addressRequest: CreateAddressReq, id?: number) => void;
}) {
  const { t } = useTranslation();
  const [addressId, setAddressId] = useState<number>();
  const [addresses, setAddresses] = useState<IAddress[]>();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const addrs = getAnonymousAddresses();
    setAddresses(addrs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnShowChangeAddressModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnSelectAddressItem = (id: number) => {
    setAddressId(id);
    setOpenModal(false);
  };

  const initData = useMemo(() => {
    return (
      (addressId && addresses?.find((item) => item.id === addressId)) ||
      undefined
    );
  }, [addresses, addressId]);

  const handleOnChangeAddress = (data: any) => {
    onChangeAddress(data);
  };

  return (
    <>
      <div className="pt-4">
        <AddressInput
          initAddress={initData}
          onUpdateData={handleOnChangeAddress}
        />
      </div>
      {!!addresses?.length && (
        <div className="text-center pt-2">
          <span
            className="underline cursor-pointer"
            onClick={handleOnShowChangeAddressModal}
          >
            {t("label.recentAddresses")}
          </span>
        </div>
      )}

      {openModal && (
        <Modal
          open={openModal}
          onCancel={handleCloseModal}
          width={768}
          footer={
            <>
              <StyledButton onClick={handleCloseModal} className="outline">
                {t("button.cancel")}
              </StyledButton>
            </>
          }
        >
          <div className="change-address-modal">
            <div className="mt-title-lg flex justify-center pb-[24px]">
              Current addresses
            </div>
            <div>
              <div className="address-list">
                {addresses?.map((item: any) => (
                  <div
                    onClick={() => handleOnSelectAddressItem(item.id)}
                    className={classNames(
                      "address-item",
                      item.id === addressId && "active"
                    )}
                    key={item.id}
                  >
                    <div className="label-container">
                      <div>
                        <div className="label">{item.value}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
