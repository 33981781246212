import { Checkbox, Popover } from "antd";
import { useAtomValue } from "jotai";
import { throttle } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MTInput from "@app-components/input/MTInput";
import {
  DISTRICT_WARD_DATA,
  PROVINCE_DATA,
  WARD_DATA,
} from "@app-constants/address";
import { isLoggedAtom } from "@app-jotai/auth";

import AddressPopover from "./AddressPopover";

export default function AddressInput({
  initAddress,
  onUpdateData,
}: {
  initAddress?: {
    value: string;
    primary: boolean;
  };
  onUpdateData: ({
    value,
    primary,
  }: {
    value: string;
    primary: boolean;
  }) => void;
}) {
  const isLogged = useAtomValue(isLoggedAtom);
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [ward, setWard] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);

  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const listProvinces = useMemo(
    () =>
      Object.values(PROVINCE_DATA).sort((a, b) => {
        const textA = a.slug.toUpperCase();
        const textB = b.slug.toUpperCase();
        return textA.localeCompare(textB);
      }),
    []
  );

  const listDistricts = useMemo(
    () =>
      Object.values(DISTRICT_WARD_DATA)
        .filter((i) => i.parent_code === province)
        .sort((a, b) => {
          const textA = a.name_with_type.toUpperCase();
          const textB = b.name_with_type.toUpperCase();
          return textA.localeCompare(textB);
        }),
    [province]
  );

  const listWards = useMemo(
    () =>
      Object.values(WARD_DATA)
        .filter((i) => i.parent_code === district)
        .sort((a, b) => {
          const textA = a.slug.toUpperCase();
          const textB = b.slug.toUpperCase();
          return textA.localeCompare(textB);
        }),
    [district]
  );

  const addressPreview = useMemo(
    () =>
      [
        WARD_DATA[ward]?.name_with_type,
        DISTRICT_WARD_DATA[district]?.name_with_type,
        PROVINCE_DATA[province]?.name_with_type,
      ].filter((i) => !!i),
    [district, province, ward]
  );

  const reviewData = useMemo(
    () =>
      addressPreview?.length
        ? addressPreview.join(", ")
        : t("label.fullAddress"),
    [addressPreview, t]
  );

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleUpdateData = () => {
    const wardData = WARD_DATA[ward];
    onUpdateData({
      value: ward && address ? `${address}, ${wardData.path_with_type}` : "",
      primary: isPrimary,
    });
  };

  useEffect(() => {
    const handleResize = throttle((e: any) => {
      setWidth(window.innerWidth);
    }, 200);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleUpdateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, province, district, ward, isPrimary]);

  useEffect(() => {
    if (initAddress?.value) {
      const splitData = initAddress.value?.split(",").reverse();
      let address: string[] = [],
        pText = "",
        dText = "",
        wText = "";

      for (let index = 0; index < splitData.length; index++) {
        const text = splitData[index];
        if (index === 0) {
          pText = text?.trim();
        } else if (index === 1) {
          dText = text?.trim();
        } else if (index === 2) {
          wText = text?.trim();
        } else {
          address = [text.trim()].concat(address);
        }
      }

      setAddress(address.join(", "));

      const provinceCode = pText
        ? Object.values(PROVINCE_DATA).find((i) => i.name_with_type === pText)
            ?.code || ""
        : "";

      const districtCode = dText
        ? Object.values(DISTRICT_WARD_DATA).find(
            (i) => i.name_with_type === dText && i.parent_code === provinceCode
          )?.code || ""
        : "";

      const wardCode = wText
        ? Object.values(WARD_DATA).find(
            (i) => i.name_with_type === wText && i.parent_code === districtCode
          )?.code || ""
        : "";

      setProvince(provinceCode);
      setDistrict(districtCode);
      setWard(wardCode);
      setIsPrimary(initAddress.primary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initAddress]);

  return (
    <div className="address-input">
      <div>
        <Popover
          content={
            open ? (
              <AddressPopover
                ward={ward}
                district={district}
                province={province}
                listProvinces={listProvinces}
                listDistricts={listDistricts}
                listWards={listWards}
                onSelectProvice={(value) => {
                  setProvince(value);
                  setDistrict("");
                  setWard("");
                }}
                onSelectDistrict={(value) => {
                  setDistrict(value);
                  setWard("");
                }}
                onSelectWard={(value) => {
                  setWard(value);
                }}
                onClose={() => setOpen(false)}
              />
            ) : null
          }
          trigger="click"
          arrow={false}
          placement="bottom"
          overlayStyle={{
            width: Math.min(width - 30 - 24 * 2, 720),
          }}
          overlayClassName="address-popover"
          open={open}
          onOpenChange={handleOpenChange}
        >
          <div className="py-[8px] border-b-[1px] text-[16px]">
            {reviewData}
          </div>
        </Popover>
      </div>
      <div className="pt-4">
        <MTInput
          label={t("input.address.label")}
          value={address}
          onChangeText={(value) => setAddress(value)}
        />
      </div>

      {isLogged && (
        <div className="pt-4">
          <Checkbox
            checked={isPrimary}
            onChange={(e) => setIsPrimary(e.target.checked)}
          >
            {t("label.defaultAddress")}
          </Checkbox>
        </div>
      )}
    </div>
  );
}
